import { select } from 'redux-most';
import { switchMap, fromEagerPromise, ignoreElements } from 'most-adjunct';
import * as most from 'most';
import * as r from 'ramda';
import * as signals from '../../signals';
import * as messages from '../../../domain/messages';
import {
  stopSubmit,
  setSubmitSucceeded,
  initialize,
  getFormValues,
} from 'redux-form';
import { hasOptoutCookie } from '../../../domain/selectors';
import { setDefaultCookieConsent } from './utils';

function createOptInFlow(saveInteraction, values, form, actions, store, submit) {
  const categoryKeys = r.pipe(
    r.pick([
    'analytics',
    'support',
    'marketing',
    ]),
  )(values);

  const after = r.pipe(
    select(messages.OPTED_IN),
    switchMap(() => {
      const savingInteraction$ = fromEagerPromise(() => saveInteraction(365));
      const state = store.getState();
      const values = getFormValues(form)(state);

      return most.mergeArray([
        most.from([
          stopSubmit(form),
          setSubmitSucceeded(form),
        ]).delay(1000),
        // 2300 = 1s pending + 1s delay + 0.3s animation
        most.of(initialize(form, values)).delay(2300),
        ignoreElements(savingInteraction$),
      ]);
    }),
  )(actions);

  return most.merge(
    after,
    most.of(signals.optIn(categoryKeys, form, submit)),
  );
}

function createOptOutFlow(saveInteraction, values, form, actions, store, submit) {
  const after = r.pipe(
    select(messages.OPTED_OUT),
    switchMap(() => {
      const savingInteraction$ = fromEagerPromise(() => saveInteraction(90));


      return most.merge(
        most.of(stopSubmit(form)),
        ignoreElements(savingInteraction$),
      );
    }),
  )(actions);

  return most.merge(
    after,
    most.of(signals.optOut(form)),
  );
}

const createOptFlow = r.pipe(
  r.unapply(r.identity),
  r.ifElse(
    r.anyPass([
      r.pathEq([ 1, 'submit' ], 'accept'),
      r.pathEq([ 1, 'submit' ], 'confirm'),
    ]),
    r.apply(createOptInFlow),
    r.apply(createOptOutFlow),
  ),
);

export default function createSubmitFormEpic(
  saveInteraction,
) {
  return (actions, store) => r.pipe(
    select(signals.SUBMIT_FORM),
    switchMap((action) => {
      const {
        payload: {
          source,
          values: formValues,
        },
        meta: {
          form,
          submit,
        },
      } = action;
      const state = store.getState();
      const stateValues = getFormValues(form)(state);
      const values = {
        ...stateValues,
        ...formValues,
      };

      /*
        we need to make sure OPTOUTMULTI exists, otherwise will error.
        if it doesn't exist, we add it as the default
        0:0|c2:1|c1:1|c4:1|c3:1
      */
      if (!hasOptoutCookie) {
        setDefaultCookieConsent();
      }

      const optFlow = createOptFlow(saveInteraction, values, form, actions, store, submit);

      return optFlow;
    }),
  )(actions);
}
