import { select } from 'redux-most';
import { switchMap, fromEagerPromise } from 'most-adjunct';
import * as most from 'most';
import * as r from 'ramda';
import * as signals from '../../signals';
import * as messages from '../../../domain/messages';
import {
  getCategories,
  isExpanded,
} from '../../../domain/selectors';

export default function createOptInEpic(
  optInRepo,
  cleanseRepo,
  emitEvent,
  fetchOptsRepo,
) {
  return (actions, store) => r.pipe(
    select(signals.OPT_IN),
    switchMap((action) => {
      const {
        payload: {
          categories,
        },
        meta: {
          form,
          submit,
        },
      } = action;

      const state = store.getState();
      const categoryKeyIdMap = getCategories(state);
      const options = fetchOptsRepo(categoryKeyIdMap);
      const optIntoAll = form !== 'inlinePrivacy' && submit === 'accept';

      const categorySettings = r.mergeDeepRight(
        options,
        r.map((v) => {
          return {
            value: optIntoAll ? true : v,
          };
        }, categories),
      );

      const optingIn$ = fromEagerPromise(() => {
        return optInRepo(categorySettings, 365)
          .then(r.tap(() => {
            const categories = r.map(
              ({
                id,
                value: enabled,
              }) => ({
                id,
                enabled,
              }),
              categorySettings,
            );
            emitEvent('@upm/opted-in', {
              categories,
            });
          }))
          .then(() => messages.optedIn(form));
      });

      return most.merge(
        most.of(messages.optInRequested()),
        optingIn$,
      );
    }),
  )(actions);
}
