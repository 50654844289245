/*
This file is used to generate the cdn <script> tag
you can drop into any page like magic
*/
import createComposition from './composition';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as r from 'ramda';
import { BreakpointRoot } from '@ux/responsive';
import TypographyImporter from "@ux/typography-importer";
import { ThemeProvider } from '@team-griffin/react-dye';

const PrivacyRibbon = React.lazy(() => import('./presentation/components/privacy-ribbon/ConnectedPrivacyRibbon'));
const InlinePrivacy = React.lazy(() => import('./presentation/components/inline-privacy/ConnectedInlinePrivacy'));

export function privacyManager(config) {
  const patchedConfig = r.pipe(
    r.mergeDeepWith(r.concat, r.__, {
      cookiesToPreserve: [],
    }),
    r.mergeDeepRight(r.__, {
      locale: 'en',
      // cookiesToPreserve: [],
      domain: {
        domain: r.path(['utag', 'cfg', 'domain'], window),
      },
      theme: {
        palette: {
          grey01: '#1a1a1a',
          grey02: '#282828',
          grey03: '#333',
          grey04: '#484848',
          grey05: '#666',
          grey06: '#848484',
          grey07: '#909090',
          grey08: '#999',
          grey09: '#ccc',
          grey10: '#eee',
          grey11: '#f2f2f2',
          grey12: '#f7f7f7',

          red01: '#cc0000',
          red02: '#f4c7c7',

          yellow01: '#EDD000',
          yellow02: '#FAF1B2',

          green01: '#7FA800',
          green02: '#D8E5B2',
        },
      },
      tealium: {},
    }),
  )(config);

  const forcedStyles = (context, useNewLayout) => {
    if (useNewLayout) {
      return `
        ${context} * {
          box-sizing: border-box;
        }
        ${context} .UPM__PrivacyRibbon__SettingsToggle {
          background-color: ${patchedConfig.theme.palette.grey10};
        }
        ${context} .UPM__PrivacyRibbon__SettingsToggle:hover {
          background-color: ${patchedConfig.theme.palette.grey11};
        }
      `;
    }

    return `
      ${context} * {
        box-sizing: border-box;
      }
    `;
  }

  function run() {
    const { store, init } = createComposition({
      config: patchedConfig,
    });

    init();

    if (patchedConfig.tealium.env && patchedConfig.tealium.profile) {
      if (patchedConfig.bannerDomSelector != null) {
        const banner = document.querySelector(patchedConfig.bannerDomSelector);
        if (banner) {
          render(
            <Provider store={store}>
              <IntlProvider locale={patchedConfig.locale} messages={patchedConfig.messages}>
                <ThemeProvider theme={patchedConfig.theme}>
                  <BreakpointRoot>
                    <TypographyImporter theme={patchedConfig.theme} />
                    <div>
                      <style>{forcedStyles('.UPM__PrivacyModal', patchedConfig.useNewLayout)}</style>
                      <React.Suspense fallback={null}>
                        <PrivacyRibbon
                          theme={patchedConfig.theme}
                          categories={patchedConfig.categories}
                          privacyPolicyUrl={patchedConfig.privacyPolicyUrl}
                          market={patchedConfig.market}
                          useNewLayout={patchedConfig.useNewLayout}
                        />
                      </React.Suspense>
                    </div>
                  </BreakpointRoot>
                </ThemeProvider>
              </IntlProvider>
            </Provider>,
            banner,
          );
        }
      }

      if (patchedConfig.inlineDomSelector != null) {
        const inline = document.querySelector(patchedConfig.inlineDomSelector);
        if (inline) {
          render(
            <Provider store={store}>
              <IntlProvider locale={patchedConfig.locale} messages={patchedConfig.messages}>
                <BreakpointRoot>
                  <TypographyImporter theme={patchedConfig.theme} />
                  <div>
                    <style>{forcedStyles('.UPM__InlinePrivacy')}</style>
                    <React.Suspense fallback={null}>
                      <InlinePrivacy theme={patchedConfig.theme} categories={patchedConfig.categories} />
                    </React.Suspense>
                  </div>
                </BreakpointRoot>
              </IntlProvider>
            </Provider>,
            inline,
          );
        }
      }
    }
  }

  run();
}

export default null;

setTimeout(function () {
  // The idea being here that the parent page
  // is able to load in the script as an async to
  // be non-blocking. However, if this is
  // done then the parent page would have no idea
  // when to interact with the script. Therefore,
  // we emit an even to listen to to being operations
  const loadedEvent = document.createEvent('CustomEvent');
  loadedEvent.initCustomEvent('@upm/loaded', false, false, {});
  document.dispatchEvent(loadedEvent);
}, 0);
